<template lang="pug">
  v-btn(rounded small class="mr-3" @click="dialog = true")
    v-icon mdi-content-save
    v-dialog(v-model="dialog" width="unset" :persistent="loading")
      v-card
        v-card-title {{ loading ? 'Загрузка...' : 'Выберите формат экспорта' }}
        v-card-text(v-if="loading")
          v-progress-linear(color="deep-purple accent-4" indeterminate rounded height="6")
        v-card-actions(v-if="!loading")
          div(diplay="flex")
            v-select(v-if="notypes == null" v-model="type" class="ma-3" item-text="title" item-value="value" :items="types" label="Шаблон")
            v-btn(@click="getExcel" class="ma-3" elevation="2" v-if="excel || id") Excel
              v-icon mdi-microsoft-excel
            v-btn(text @click="getZip" class="ma-3" elevation="2" v-if="zip || id") Zip
              v-icon mdi-folder-zip
            v-btn(text @click="getZipImage" class="ma-3" elevation="2" v-if="zip || id") Zip с картинками
              v-icon mdi-folder-zip
            v-btn(elevation="2" class="ma-3" @click="dialog = false") Отмена
</template>


<script>
    // import { config, $_app_server } from '@/_helpers'
    import { reportService } from '@/_services'

    export default {
        props: ['id', 'excel', 'zip', 'notypes'],
        data: () => ({
          dialog: false,
          loading: false,
          type: 0,
          types: [
            { title: 'БМК', value: 1 },
            { title: 'Махеев', value: 2 },
            { title: 'По полям в проекте', value: 0 }
          ]
        }),
        methods: {
            getExcel() {
              if (this.id != null)
                this.getExport(reportService.excel, this.id, 0, {}, 0)
              else if (this.excel != null)
                this.getExport(this.excel.method, this.excel.id, 0, this.excel.params, 0)
            },
            getZip() {
              console.log(this.zip);
              
              if (this.id != null)
                this.getExport(reportService.zipLink, this.id, 0, {}, 1)
              else if (this.zip != null)
                this.getExport(this.zip.method, this.zip.id, 0, this.zip.params, 1)
            },
            getZipImage() {
              if (this.id != null)
                this.getExport(reportService.zipLink, this.id, 1, {}, 1)
              else if (this.zip != null)
                this.getExport(this.zip.method, this.zip.id, 1, this.zip.params, 1)
            },
            getExport(method, id, need_foto, params, typeExport) {
              this.loading = true
              let closer = () => {
                this.loading = false
                this.dialog = false
              }
              let createLink = (filename, url) => {
                let tempEl = document.createElement("a");
                document.body.appendChild(tempEl);
                tempEl.style = "display: none";
                tempEl.href = url;
                tempEl.download = filename;
                tempEl.click();
                tempEl.remove();
              }
              // let time = performance.now();
              // console.log('START', time, performance.now());
              
              method(id, Object.assign({
                template: this.type,
                need_foto: need_foto
              }, params)).then(function(response) {
                if (typeExport == 0) {
                // console.log('END', performance.now() - time, response);
                  if ((typeof response.data) == 'string') {
                    let content_disposition = response.headers['content-disposition'];
                    let idx = content_disposition.indexOf('filename*=UTF-8\'\'');
                    let filename = idx != -1 ? decodeURIComponent(content_disposition.substring(idx + 17)) : 'export';
                    createLink(filename, response.data)
                  } else {
                    let content_disposition = response.headers['content-disposition'];
                    let idx = content_disposition.indexOf('filename*=UTF-8\'\'');
                    let filename = idx != -1 ? decodeURIComponent(content_disposition.substring(idx + 17)) : 'export';
                    let blob = response.data;
                    let objectURL = URL.createObjectURL(blob);
                    createLink(filename, objectURL)
                  }
                  closer()
                } else {
                  const uuid = response.data.uuid;
                  let intervalId = setInterval(() => {
                    reportService.checkUpload(uuid).then((data) => {
                      if (data.status == 'END') {
                        createLink(data.name, data.path)
                      }
                      if (data.status == 'END' || data.status == 'ERROR') {
                        clearInterval(intervalId)
                        closer()
                      }
                    }).catch(() => {
                      clearInterval(intervalId)
                      closer()
                    })
                  }, 3000)
                }
              }).catch((e) => {
                console.log(e)
                closer()
              })
            }
        }
    }
</script>
